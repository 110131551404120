import "./modules/script";
import "./modules/map/mapalex";

import AOS from "aos";
// ..
AOS.init();
AOS.init({
	// Global settings:
	disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
	startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
	initClassName: "aos-init", // class applied after initialization
	animatedClassName: "aos-animate", // class applied on animation
	useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
	disableMutationObserver: false, // disables automatic mutations' detections (advanced)
	debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
	throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 100, // offset (in px) from the original trigger point
	delay: 0, // values from 0 to 3000, with step 50ms
	duration: 400, // values from 0 to 3000, with step 50ms
	easing: "ease", // default easing for AOS animations
	once: false, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
	anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

onElementHeightChange(document.body, function () {
	AOS.refresh();
	ScrollTrigger.refresh();
});

function onElementHeightChange(elm, callback) {
	var lastHeight = elm.clientHeight;
	var newHeight;

	(function run() {
		newHeight = elm.clientHeight;
		if (lastHeight !== newHeight) callback();
		lastHeight = newHeight;

		if (elm.onElementHeightChangeTimer) {
			clearTimeout(elm.onElementHeightChangeTimer);
		}

		elm.onElementHeightChangeTimer = setTimeout(run, 150);
	})();
}

gsap.config({ trialWarn: false });
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

//import Swiper, { Navigation, Pagination } from 'swiper';
// if($('body').hasClass('home')){
//   const swiper = new Swiper(".home_swipe", {
//     loop: true,
//     spaceBetween: 0,
//     grabCursor: true,
//     slidesPerView:1,
//     speed: 1000,
//     centeredSlides: true,
//     navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   pagination: {
//     el: ".swiper-pagination",
//   },
//   });

//   swiper.on('slideChange', function () {
//     gsap.to(swiper.slides[swiper.activeIndex], {scale:1, opacity:1})
//     gsap.to(swiper.slides[swiper.previousIndex], {opacity:0.3, scale:0.8})
//     swiper.slides[swiper.previousIndex].animation.pause(0)
//     swiper.slides[swiper.activeIndex].animation.restart()
//   });

//   swiper.slides.forEach((slide, index)=>{
//     let letter = slide.querySelector("h1")
//     let description = slide.querySelector("span")
//     let chars = SplitText.create(description, {type:"chars"})
//     let tl = gsap.timeline({paused:true})
//     tl.from(letter, {scale:0, opacity:0, ease:"back", duration:1})
//       .from(chars.chars, {opacity:0, yPercent:50, stagger:0.02}, "-=0.5")
//     slide.animation = tl
//   })

//   swiper.slides[1].animation.play();
// }

/*End landing animation*/

// ------------------------------------------- //

// Smooth scroller
let smoother = ScrollSmoother.create({
	wrapper: "#smooth-wrapper",
	content: "#smooth-content",
	smooth: 2,
	smoothTouch: 0.1,
	effects: true,
});

smoother.effects(".image_parallax img", { speed: "auto" });

gsap.to(".bar", 1.5, {
	height: 0,
	// stagger: {
	// 	amount: 0.5,
	// },
	ease: "circ.inOut",
});
if (!document.querySelector(".page-template-tmpl_home")) {
	gsap.to(".loadertext", 1.1, {
		scale: 10,
		opacity: 0,
		ease: "circ.Out",
	});
}

gsap.to(".site-header", 1.5, {
	delay: 2,
	opacity: 1,
	ease: "power4.inOut",
});

if (document.querySelector(".animationtitle")) {
	gsap.to(".animationtitle", 1.5, {
		delay: 1,
		opacity: 1,
		y: 0,
		ease: "power4.inOut",
	});
}

window.addEventListener("load", () => {
	gsap.registerPlugin(CustomEase, ScrollTrigger);

	// Wrap every letter
	const textRevealElements = document.querySelectorAll(".reveal-text");

	textRevealElements.forEach((element) => {
		element.innerHTML = element.textContent.replace(
			/([-A-Za-z0-9Α-Ωα-ωΆ-ω-Ώά-ώ!$#%^&*@()_+|~=`{}\[\]:";'<>?,.\/]+)/g,
			'<div class="word">$1</div>'
		);

		let words = element.querySelectorAll(".word");
		words.forEach((word) => {
			word.innerHTML = word.textContent.replace(
				/[-A-Za-z0-9Α-Ωα-ωΆ-ω-Ώά-ώ!$#%^&*@()_+|~=`{}\[\]:";'<>?,.\/]/g,
				"<div class='perspective'><div class='letter'><div>$&</div></div></div>"
			);
		});

		const letters = element.querySelectorAll(".letter");

		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: element,
				toggleActions: "restart none none reset",
			},
		});
		tl.set(element, { autoAlpha: 1 });
		tl.fromTo(
			letters,
			4,
			{
				transformOrigin: "center",
				rotationY: 90,
				x: 30,
			},
			{
				rotationY: 0.1,
				x: 0,
				stagger: 0.02,
				ease: CustomEase.create("custom", "M0,0 C0.425,0.005 0,1 1,1 "),
			}
		);
	});
});

// bottom-to-top-reveal

// window.addEventListener("load", function () {
gsap.registerPlugin(ScrollTrigger, SplitText);

document.querySelectorAll(".animate-text").forEach((element) => {
	const childSplit = new SplitText(element, {
		type: "lines",
		linesClass: "split-child",
	});
	const parentSplit = new SplitText(element, {
		type: "lines",
		linesClass: "split-parent",
	});

	function ish1Heading(el) {
		return el.tagName.toLowerCase() === "h1" ? 1 : 0.2;
	}

	// Animate each element
	gsap.from(childSplit.lines, {
		duration: 1.6,
		delay: () => ish1Heading(element),
		yPercent: 100,
		ease: "power4",
		stagger: 0.2,
		scrollTrigger: {
			trigger: element, // Use the current element as trigger
			start: "top 80%",
			markers: false,
			toggleActions: "play none none reverse",
		},
	});
});
// });

/*Menu Hover*/
// const menu_items = gsap.utils.toArray('.menu-item');
//
// menu_items.forEach((sections, index) => {
//     const menu_heading = sections.querySelector('.menu_chars');
//     let split_menu = SplitText.create(menu_heading, { type: "chars" });
//     const chars = split_menu.chars;
//
//     // Define the GSAP animation function
//     const animateMenu = () => {
//         gsap.fromTo(
//             chars,
//             {
//                 y: 50,
//                 opacity: 0,
//             },
//             {
//                 y: 0,
//                 opacity: 1,
//                 stagger: 0.05,
//                 duration: 0.7,
//                 ease: "power4.out",
//             }
//         );
//     };
//
//     // Add mouseenter event listener
//     sections.addEventListener('mouseenter', () => {
//         animateMenu();
//     });
//
//     // Add mouseleave event listener (if you want to reverse the animation)
//     sections.addEventListener('mouseleave', () => {
//         // Optionally reverse the animation on mouseleave
//         gsap.to(chars, {
//             y: 0,
//             opacity: 0,
//             stagger: 0,
//             duration: 0,
//             ease: "power4.in",
//         });
//     });
//
//
// });
