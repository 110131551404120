const isTabletDevice = window.matchMedia("only screen and (max-width: 991px)").matches;
const isSmallDevice = window.matchMedia("only screen and (max-width: 768px)").matches;
const isHomePage = document.body.classList.contains("page-template-tmpl_home");
const isPortfolioPage = document.body.classList.contains("page-template-tmpl_portfolio");

/*scroll header*/
$(window).scroll(function () {
	if ($(document).scrollTop() > 700) {
		$(".header_wrapper").addClass("scrolled");
	} else {
		$(".header_wrapper").removeClass("scrolled");
	}
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function (event) {
	var st = $(this).scrollTop();
	if (st > lastScrollTop) {
		// downscroll code
		$(".header_wrapper").addClass("down");
	} else {
		// upscroll code
		$(".header_wrapper").removeClass("down");
	}
	lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on("click", function () {
	return false;
});

/*More text*/
$(".moretext").on("click", function () {
	$(this).parent().find(".more_text").slideToggle();
	return false;
});

// Form
$("p.open_hide_box").on("click", function () {
	$(this).parent().find(".hide_box").slideToggle();
	return false;
});

/*Open Menu*/
$(".menu_button").on("click", function () {
	if ($(this).hasClass("is-active")) {
		// $(".headermenu").removeClass("is-active2");
		$("body").css("overflow-y", "scroll");
		$(this).removeClass("is-active");
		nav_tl.reverse();
		$(".menu_button").removeClass("is-active");
		$(".headermenu").removeClass("is-active");
		$(".header_wrapper").removeClass("is-active");
		//$(".headermenu").addClass("is-active2");
	} else {
		$(this).addClass("is-active");
		nav_tl.play();
		$(".menu_button").addClass("is-active");
		jQuery("body").css("overflow-y", "hidden");
		$(".headermenu").addClass("is-active");
		$(".header_wrapper").addClass("is-active");
		//$(".headermenu").removeClass("is-active2");
	}
});

/*Faq*/
$(".faq").on("click", function () {
	$(this).find(".hidden_content").slideToggle();
	if ($(this).find(".button").hasClass("active")) {
		$(this).find(".button").removeClass("active");
	} else {
		$(this).find(".button").addClass("active");
	}
	return false;
});

// --------------------------
// Calculate the header height
function setHeaderHeight() {
	const header = document.querySelector(".site-header");
	if (!header) return;

	const headerHeight = header.offsetHeight;
	document.body.style.setProperty("--header-h", `${headerHeight}px`);
}

document.addEventListener("DOMContentLoaded", setHeaderHeight);
window.addEventListener("resize", setHeaderHeight);

// -----------------
// Product overview
$(".openpackaging").on("click", function () {
	$(this).parent().find(".hidden_text").slideToggle();
	if ($(this).hasClass("active")) {
		$(this).removeClass("active");
	} else {
		$(this).addClass("active");
	}
	return false;
});

// -------------------
// Landing home screen
document.addEventListener("DOMContentLoaded", function () {
	const featuredSection = document.getElementById("featured");
	if (isHomePage && featuredSection && window.scrollY == 0) {
		$("body").css("overflow-y", "hidden");
		$("body").css("pointer-events", "none");
		setTimeout(function () {
			$("body").css("overflow-y", "scroll");
			$("body").css("pointer-events", "auto");
		}, 2800);
	}
});

// Image Tile Animation
if (document.body.classList.contains("page-template-tmpl_contact")) {
	document.addEventListener("DOMContentLoaded", function () {
		const images = document.querySelectorAll(".tile-animation");

		images.forEach((image) => {
			setTimeout(() => {
				image.classList.add("active");
			}, 1000);

			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === "attributes" && mutation.attributeName === "class") {
						if (image.classList.contains("active") && image.classList.contains("aos-animate")) {
							setTimeout(() => {
								image.classList.remove("active");
							}, 1400);

							observer.disconnect();
						}
					}
				});
			});
			observer.observe(image, { attributes: true });
		});
	});
}

// Awards swiper
const awardsSwiper = new Swiper(".awards-swiper", {
	loop: true,
	freeMode: true,
	spaceBetween: 0,
	grabCursor: true,
	slidesPerView: 6,
	loop: true,
	autoplay: {
		delay: 1,
		disableOnInteraction: true,
	},
	freeMode: true,
	speed: 6000,
	freeModeMomentum: false,
	breakpoints: {
		0: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		550: {
			slidesPerView: 3,
		},
		768: {
			slidesPerView: 4,
		},
		1200: {
			slidesPerView: 5,
		},
	},
});

// Homepage services hover effect
if (isHomePage && !isTabletDevice) {
	const elementsWorks = document.querySelectorAll(".services-list > .service");
	const slidePicWorks = document.querySelector("#services-gallery");
	const slidePicsWorks = document.querySelector("#images");

	gsap.set(slidePicWorks, { autoAlpha: 0 });

	elementsWorks.forEach((element, index) => {
		element.addEventListener("mouseenter", function () {
			gsap.to(slidePicsWorks, {
				marginTop: `-${280 * index}px`,
				duration: 0.2,
				ease: "power1",
			});
		});

		element.addEventListener("mouseleave", function () {
			gsap.to(element, { color: "initial", duration: 0.2, ease: "power1" });
		});
	});

	window.addEventListener("mousemove", function (e) {
		gsap.to(slidePicWorks, {
			top: `${e.pageY}px`,
			left: `${e.pageX}px`,
			xPercent: -20,
			yPercent: -45,
			duration: 0.2,
			ease: "power1",
		});
	});

	document.querySelector(".services-list").addEventListener("mouseenter", function () {
		gsap.to(slidePicWorks, {
			autoAlpha: 1,
			duration: 0.2,
			ease: "power1",
		});
	});

	document.querySelector(".services-list").addEventListener("mouseleave", function () {
		gsap.to(slidePicWorks, {
			autoAlpha: 0,
			duration: 0.2,
			ease: "power1",
		});
	});
}

//Image mouse follower
function initCursorTracking(wrapperSelector, hoverItemSelector) {
	const imageCursorContainer = $(".image-cursor-container");
	const followingImages = $(".following-image");

	$(wrapperSelector).mousemove(function (e) {
		var relX = e.pageX;
		var relY = e.pageY;

		imageCursorContainer
			.css({
				left: relX,
				top: relY,
			})
			.addClass("active");
	});

	$(hoverItemSelector).hover(
		function () {
			var index = $(this).data("index");
			followingImages.removeClass("active");
			followingImages.filter("[data-index='" + index + "']").addClass("active");
		},
		function () {
			followingImages.removeClass("active");
		}
	);

	$(wrapperSelector).mouseleave(function () {
		imageCursorContainer.removeClass("active");
		followingImages.removeClass("active");
	});
}

if (window.innerWidth > 768) {
	// Initialization for different sections
	initCursorTracking(".awards-wrapper", ".award");
	// initCursorTracking(".services-wrapper", "li.service");
}

// inner post video handling
document.addEventListener("DOMContentLoaded", function () {
	const video = document.getElementById("project-video");
	const playButton = document.getElementById("play-button");

	if (video && playButton) {
		playButton.addEventListener("click", function () {
			video.controls = true;
			video.muted = false;
			video.play();
			video.classList.remove("paused");
			playButton.style.display = "none";
		});
	}
});

// Assuming you have GSAP and ScrollTrigger loaded
// if window width is  > 768 Pin .left within #portfolio_overview until the bottom of .right is reached

function getTravelDistance() {
	let inner_height_project_right = $("#inner_content .content-wrapper .gallery").height();
	let inner_height_project_left = $("#inner_content .content-wrapper .content").height();
	return inner_height_project_right - inner_height_project_left;
}

if (window.innerWidth > 768 && document.querySelector(".single-project")) {
	ScrollTrigger.create({
		trigger: "#inner_content",
		start: "top top",
		end: () => `+=${getTravelDistance()}`,
		markers: false,
		pin: "#sticky-left",
		toggleActions: "play none none pause",
	});
}

function getTravelDistance_overview() {
	let inner_height_project_right_overview = $("#portfolio_overview .right").height();
	let inner_height_project_left_overview = $("#portfolio_overview .left").height();
	return inner_height_project_right_overview - inner_height_project_left_overview;
}

if (window.innerWidth > 768 && document.querySelector(".page-template-tmpl_portfolio")) {
	ScrollTrigger.create({
		trigger: "#portfolio_overview",
		start: "top top",
		end: () => `+=${getTravelDistance_overview()}`,
		markers: false,
		// pinType: "fixed",
		pin: "#sticky-left",
		toggleActions: "play none none pause",
	});
}

const sections = document.querySelectorAll(".nav-color-trigger");
const clippathContainer = document.querySelector(".nav-color-trigger .right-column");

if (isHomePage && !isTabletDevice) {
	gsap.utils.toArray(sections).forEach((section) => {
		ScrollTrigger.create({
			trigger: section,
			start: "15% 55%",
			end: "50% top",
			pin: "#left-column",
			toggleActions: "play none none play",
			toggleClass: { targets: "body", className: "white" },
			onEnter: () => clippathContainer.classList.add("clipped"),
			onLeaveBack: () => clippathContainer.classList.remove("clipped"),
			// markers: true,
		});
	});
}

if (isHomePage && !isSmallDevice) {
	//Animate Images
	function mouseMoveFunc(evt) {
		const maxX1 = gsap.getProperty(".container-image-1", "width") * 0.35;
		const maxX2 = gsap.getProperty(".container-image-2", "width") * 0.5;
		const maxX3 = gsap.getProperty(".container-image-3", "width") * 0.3;
		const maxX4 = gsap.getProperty(".container-image-4", "width") * 0.4;
		const maxX5 = gsap.getProperty(".main-heading", "width") * 0.05;

		const percent = gsap.utils.normalize(0, innerWidth, evt.pageX);

		gsap.to(".container-image-1", {
			duration: 0.5,
			x: percent * maxX1 - maxX1 / 3,
			//rotationY: -(percent * maxRot - maxRot / 10),
			overwrite: true,
		});

		gsap.to(".container-image-2", {
			duration: 0.5,
			y: percent * maxX2 - maxX2 / 3,
			//rotationY: -(percent * maxRot - maxRot / 10),
			overwrite: true,
		});

		gsap.to(".container-image-3", {
			duration: 0.5,
			x: -(percent * maxX3 - maxX3 / 3),
			//rotationY: -(percent * maxRot - maxRot / 10),
			overwrite: true,
		});

		gsap.to(".container-image-4", {
			duration: 0.5,
			y: percent * maxX4 - maxX4 / 3,
			//rotationY: -(percent * maxRot - maxRot / 10),
			overwrite: true,
		});

		gsap.to(".main-heading", {
			duration: 1,
			x: percent * maxX5 - maxX5 / 3,
			//rotationY: -(percent * maxRot - maxRot / 10),
			overwrite: true,
		});
	}
	window.addEventListener("mousemove", mouseMoveFunc);
}

// portfolio Page link control
if (isPortfolioPage) {
	const menuLinks = document.querySelectorAll(".sub-menu .custom-link a");
	const linkTags = document.querySelectorAll(".tag-list a");

	let url = window.location.hash;

	linkTags.forEach((tag) => {
		if (tag.hash === url) {
			tag.click();
		}
	});

	menuLinks.forEach((link) => {
		link.addEventListener("click", (e) => {
			const isMenuOpen = document.querySelector(".headermenu.is-active");

			if (isMenuOpen) {
				e.preventDefault();
				const newHash = link.hash;
				window.location.href = window.location.pathname + newHash;
				location.reload();
			}
		});
	});
}

// document.addEventListener("DOMContentLoaded", () => {
// 	const btns = document.querySelectorAll(".btn-apply");
// 	const togglers = document.querySelectorAll(".accordion__header[data-toggle]");

// 	btns.forEach((btn) => {
// 		btn.addEventListener("click", () => {
// 			console.log("is clicked");
// 			document.getElementById("application_form").scrollIntoView({ behavior: "smooth" });
// 		});
// 	});
// 	togglers.forEach((items) => {
// 		items.addEventListener("click", (e) => {
// 			const selector = e.currentTarget.dataset.toggle;
// 			const block = document.querySelector(`${selector}`);
// 			if (e.currentTarget.classList.contains("active")) {
// 				block.style.maxHeight = "";
// 			} else {
// 				block.style.maxHeight = block.scrollHeight + "px";
// 			}

// 			e.currentTarget.classList.toggle("active");
// 		});
// 	});
// });

document.addEventListener("DOMContentLoaded", () => {
	const btns = document.querySelectorAll(".btn-apply");
	const formInputSubject = document.querySelector("input[name='your-subject']");
	const togglers = document.querySelectorAll(".accordion__header[data-toggle]");
	let btnClicked = false;

	btns.forEach((btn) => {
		btn.addEventListener("click", (e) => {
			btnClicked = true;

			const locationElement = e.target.closest(".accordion__header").querySelector(".location");
			const jobNameElement = e.target.closest(".accordion__header").querySelector(".job-name");
			const jobName = jobNameElement ? jobNameElement.innerText : "";
			const locationName = jobNameElement ? locationElement.innerText : "";

			formInputSubject.value = locationName + " / " + jobName;
			document.getElementById("application_form").scrollIntoView({ behavior: "smooth" });

			setTimeout(() => {
				btnClicked = false;
			}, 200);
		});
	});

	togglers.forEach((items) => {
		items.addEventListener("click", (e) => {
			if (btnClicked) {
				return;
			}

			const selector = e.currentTarget.dataset.toggle;
			const block = document.querySelector(`${selector}`);
			if (e.currentTarget.classList.contains("active")) {
				block.style.maxHeight = "";
			} else {
				block.style.maxHeight = block.scrollHeight + "px";
			}

			e.currentTarget.classList.toggle("active");
		});
	});
});

const menuWrapper = document.querySelector(".headermenu");
const decorateImage = menuWrapper.querySelector(".image-container");
const menu = document.querySelector(".menu-main-menu-container ul.menu");
const links = menu.querySelectorAll("li a");

const nav_tl = gsap
	.timeline({
		defaults: {
			ease: "power4.inOut",
			duration: 1,
		},
	})

	.to(menuWrapper, {
		y: 0,
	})

	.to(
		decorateImage,
		{
			clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
		},
		"<+=.55"
	);

const animationConfig = {
	y: -100,
	stagger: 0.04,
	ease: "slow(0.3,0.7,false)",
};

if (!decorateImage) {
	nav_tl.from(links, animationConfig, "<-.04");
} else {
	nav_tl.from(links, animationConfig);
}

nav_tl.reverse();

// if (document.body.classList.contains("page-template-tmpl_about")) {
// 	const canvas = document.getElementById("canvas");
// 	const context = canvas.getContext("2d");

// 	const frameCount = 449;

// 	const currentFrame = (index) =>
// 		`http://localhost/nextdesign/wp-content/themes/nextdesign/assets/images/video-frames/next design_1${index
// 			.toString()
// 			.padStart(3, "0")}.jpg`;

// 	// Set up canvas
// 	canvas.width = 1920;
// 	canvas.height = 1080;
// 	canvas.style.width = "100%";
// 	canvas.style.height = "100%";
// 	canvas.style.objectFit = "cover";

// 	const images = [];
// 	let currentFrameIndex = 0;

// 	const preloadImages = () => {
// 		for (let i = 1; i <= frameCount; i++) {
// 			const img = new Image();
// 			img.src = currentFrame(i);
// 			images.push(img);

// 			if (i === 1) {
// 				img.onload = () => {
// 					context.drawImage(img, 0, 0);
// 				};
// 			}
// 		}
// 	};

// 	const updateImage = (index) => {
// 		if (images[index] && images[index].complete) {
// 			context.drawImage(images[index], 0, 0);
// 		}
// 	};

// 	gsap.registerPlugin(ScrollTrigger);
// 	const smoothness = 0.2;
// 	const tl = gsap.timeline({
// 		scrollTrigger: {
// 			trigger: "#canvas",
// 			pin: true,
// 			start: "top top",
// 			end: "+=300%",
// 			scrub: {
// 				ease: "power3.out",
// 				duration: 1,
// 			},
// 			onUpdate: (self) => {
// 				currentFrameIndex = Math.min(frameCount - 1, Math.floor(self.progress * frameCount));
// 				requestAnimationFrame(() => updateImage(currentFrameIndex));
// 			},
// 		},
// 	});

// 	preloadImages();
// }

gsap.registerPlugin(ScrollTrigger);
const video = document.getElementById("pinned-video");
const videoContainer = document.getElementById("video-container");

if (video && document.body.classList.contains("page-template-tmpl_about")) {
	ScrollTrigger.create({
		trigger: video,
		start: "top top",
		end: "+=50%",
		pin: true,
		// markers: false,
		pinSpacing: false,
		onEnter: () => video.play(),
	});
}
